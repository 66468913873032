import React, { Component } from "react";
import "./style.css";
import Card from "../UI/Card";
import Logo from "../Logo";
import Navbar from "../Navbar";
class Hero extends Component {
  state = {};
  render() {
    return (
      <div>
        <Logo />
        <Card>
          <Navbar />
        </Card>
      </div>
    );
  }
}

export default Hero;
