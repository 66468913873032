import React, { Component } from "react";
import "./style.css";
class Header extends Component {
  state = {};
  render() {
    return (
      <header className="header">
      <nav className="headerMenu">
        <a href="/">Accueil</a>
        <a href="http://www.fstg-marrakech.ac.ma/" target="_blank">FSTG Marrakech</a>
        <a href="https://www.uca.ma/" target="_blank">UCA</a>
        <a href="/contact-us">Contact</a>
      </nav>
      <ul className="socialMedia">
        <li><a href="https://www.linkedin.com/company/cluberme" target="_blank"><i className="fa fa-linkedin"></i></a></li>
        <li><a href="https://facebook.com/cluberme" target="_blank"><i className="fa fa-facebook"></i></a></li>
        <li><a href="https://instagram.com/cluberme.fstg" target="_blank"><i className="fa fa-instagram"></i></a></li>
      </ul>
    </header>
    );
  }
}

export default Header;
