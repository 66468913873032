/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import Card from "../UI/Card";

class Emploi extends Component {
  constructor(){
    super();
    this.state = {
      emplois:[],
    };
  }
  
  async componentDidMount() {
    
    // Simple GET request using fetch
    const response = await fetch('https://cpanel.fi-erme.com/api/emplois?populate=image_emploi');
    const json = await response.json();
    this.setState({ emplois: json.data });
}
  render() {   
    return (
      <Card>
        
        {this.state.emplois && this.state.emplois.map((emploi)=>(
          <>
          
          <div className="mt-4">
        <h1 className="text-center">
        {emploi.attributes.Titre}<br></br>
            <span className="secondary text-center">
            </span>
          </h1>

          <div className="mt-4 d-flex justify-content-center">
            <img src={emploi.attributes.image_emploi.data.attributes.url} className="img-fluid" alt="Responsive image" />
            {/* {emploi.attributes.image_emploi[0][1]} */}
          </div>
          </div>
        </>
        )
        )}
        
        {/* <div className="mt-4">
          <h1 className="text-center">
            Emploi du temps S2 2021/2022 <br></br>
            <span className="secondary text-center">
            </span>
          </h1>

          <div className="mt-4 d-flex justify-content-center">
            <img src={imgS2url} className="img-fluid" alt="Responsive image" />
          </div>
        </div>
        <div className="mt-4">
          <h1 className="text-center">Emploi du temps S4 2021/2022</h1>
          <div className="mt-4 d-flex justify-content-center">
            <img src={imgS4Url} className="img-fluid" alt="Responsive image" />
          </div>
        </div> */}
        
      </Card>
    );
  }
}

export default Emploi;
