import React, { Component } from "react";
import Card from "../UI/Card";

class Equipe extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Card>
          <h3 className="text-center mb-3 mt-3">
            Equipe Pédagogique et Intervenants
          </h3>
          <div className="d-flex justify-content-center">
            <div className="container">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>M. Belkhayat Driss</td>
                    <td>M. Elkari Abdeljalil </td>
                  </tr>
                  <tr>
                    <td>M. Elwarraki Elmostafa</td>
                    <td>M. Ayad Hassan</td>
                  </tr>
                  <tr>
                    <td>M. Chennani Mohamed</td>
                    <td>M. Belahrach Hassan</td>
                  </tr>
                  <tr>
                    <td>M. Douiri Rachid</td>
                    <td>M. Essaleh Lahcen</td>
                  </tr>
                  <tr>
                    <td>Mme. Belmadani Loubna</td>
                    <td>M. Serraou Driss</td>
                  </tr>
                  <tr>
                    <td>M. Chakir El Alaoui El Houcine</td>
                    <td>M. Sefiani Fouad</td>
                  </tr>
                  <tr>
                    <td>M. Ayouch Chahid</td>
                    <td>Mme. Douamna Samira</td>
                  </tr>
                  <tr>
                    <td>M. Oukili Mohamed</td>
                    <td>M. Zouani Younes</td>
                  </tr>
                  <tr>
                    <td>M. Raghay Said</td>
                    <td>M. Zidani Youssef</td>
                  </tr>
                  <tr>
                    <td>M. Belakouiri Abdelghani</td>
                    <td>M. Naji Abdenouri</td>
                  </tr>
                  <tr>
                    <td>M. Ait babram Mohamed</td>
                    <td>M. benloghfyry Anouar</td>
                  </tr>
                  <tr>
                    <td>M. Melhaoui Mustapha</td>
                    <td>M. Doubabi Said</td>
                  </tr>
                  <tr>
                    <td>M. ELBACHA Abdelhadi</td>
                    <td>M. Boulghassoul Zakaria</td>
                  </tr>
                  <tr>
                    <td>M. Benhida abdelmajid</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default Equipe;
