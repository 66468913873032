import React, { Component } from "react";
import Card from "../UI/Card";

class Debouches extends Component {
  state = {};
  render() {
    return (
      <Card>
        <div className="container-fluid mt-4">
          <h1 className="text-center">Débouchés</h1>
          <br />
          <div className="row">
            <div className="col-lg-4">
              <h3 className="">Energies Renouvelables :</h3>
            </div>
            <div className="col-lg-8">
              <p>
                Chargé de mission (ou agent de développement) en énergies
                renouvelables. 
                <br />
                Chargé d’affaires en génie thermique et climatique. 
                <br />
                Ingénieur d’études énergies renouvelables et efficacité
                énergétique. 
                <br />
                Ingénieur en génie thermique ou ingénieur thermicien. 
                <br />
                Ingénieur fluides, énergies, réseaux, environnement. 
                <br />
                Installateur mainteneur en systèmes solaires thermique et
                photovoltaïque. 
                <br />
                Ingénieur Technico-commercial thermicien économie d’énergie. 
                <br />
                Bureaux d’études. <br />
                Conception et dimensionnement d’installations solaires
                photovoltaïques. <br />
                Conception et dimensionnement d’installations solaires
                thermiques. <br />
                Conception et dimensionnement d’installations éoliennes 
                <br />
                Gestion de projets.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <h3 className="">Mobilité électrique:</h3>
            </div>
            <div className="col-lg-8">
              <p>
                Ingénieur d'étude. <br />
                Ingénieur de recherche. <br />
                Chargé d'études économiques. <br />
                Ingénieur tests et essais. <br />
                Ingénieur Chef de projet R&D. 
                <br />
                Ingénieur en charge des achats technologiques. <br />
                Ingénieur brevets. <br />
                Ingénieur procédés en environnement. <br />
                Ingénieur calcul. 
                <br />
                Chef de projet industriel. <br />
                Directeur de bureau d'études. <br />
                Directeur de programme R&D.
              </p>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default Debouches;
