import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./style.css";

class Logo extends Component {
  state = {
    images: [],
  };

  componentDidMount() {
    fetch("https://cpanel.fi-erme.com/api/images-headers?populate=*")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ images: data.data });
      })
      .catch((error) => console.error(error));
  }

  render() {
    return (
      <Carousel>
        {this.state.images.map((image) => (
          <Carousel.Item key={image.id}>
            <img
              className="d-block w-100 rounded"
              src={image.attributes.Images.data[0].attributes.url}
              alt={`Slide ${image.id}`}
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

export default Logo;