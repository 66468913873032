import React, { Component } from "react";
import "./style.css";
import Card from "./../../components/UI/Card";
import profileImg from "../../assets/img/2.png";
import profileImg2 from "../../assets/img/3.jpeg";
import profileImg3 from "../../assets/img/4.jpg";
import profileImg4 from "../../assets/img/5.jpg";
import profileImg5 from "../../assets/img/6.jpg";
import ReactGA from "react-ga";

class Home extends Component {
  state = {};
  render() {
    ReactGA.initialize("G-QRQQMQSR13");
    return (
      <React.Fragment>
        <Card>
          <div className="pt-4 pb-4 pl-4 pr-4">
            <div className="row">
              <div className="col-lg">
                <img
                  className="rounded-circle mx-auto d-block "
                  src="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2FBlue_question_mark_icon.svg.png?alt=media&token=9e536fd8-adb4-4e75-8319-44a4851a583e"
                  alt="Generic placeholder image"
                  width={90}
                  height={90}
                />
                <h3 className="mt-3">
                  Pourquoi Energies Renouvelables et Mobilité Electrique ?
                </h3>
                <p>
                  Suivre les orientations majeures de notre Pays (La station
                  NOOR à Ouarzazate pour arriver à un objectif de 52% de la
                  production électrique nationale en énergies renouvelable à
                  l’horizon 2025. Les usines Renault à Tanger et Peugeot à
                  Kenitra qui assurent une forte production de véhicules faisant
                  du Maroc le plus gros fabricant en Afrique).
                </p>
              </div>
              {/* /.col-lg */}
              <div className="col-lg">
                <img
                  className="rounded-circle mx-auto d-block"
                  src="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2F1632633.png?alt=media&token=17db9540-4214-4a5b-a9ab-232512ff4c85"
                  alt="Generic placeholder image"
                  width={90}
                  height={90}
                />
                <h3 className="mt-3">OBJECTIFS DE LA FORMATION</h3>
                <p>
                  + Former des ingénieurs polyvalents de haut niveau dans les
                  domaines des Energies Renouvelables et de la Mobilité
                  Electrique. <br />+ Répondre à la demande importante du milieu
                  socioéconomique. <br />+ Capitaliser sur une expérience de
                  plus de 20 ans acquise dans les domaines du génie électrique.
                </p>
              </div>
              {/* /.col-lg */}
              <div className="col-lg">
                <img
                  className="rounded-circle mx-auto d-block"
                  src="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2FCOMPETENCES-ERME.png?alt=media&token=6afb7894-236f-419a-a08d-501f7eaff918"
                  alt="Generic placeholder image"
                  width={90}
                  height={90}
                />
                <h3 className="mt-3">COMPETENCES A ACQUERIR</h3>
                <p>
                  + L’ensemble des disciplines majeures du génie électrique, à
                  savoir l’électronique, l’électrotechnique, l’automatique et
                  l’informatique, utilisées dans les énergies renouvelables et
                  la mobilité électrique. <br />+ La conception et la mise en
                  œuvre des installations utilisant les énergies renouvelables
                  en maitrisant les dernières technologies et développements
                  dans ces domaines.
                  <br />
                  + Le principe de fonctionnement des véhicules électriques et
                  la chaine de traction, les nouveaux modes de transport et
                  leurs tendances d’avenir.
                  <br /> + Les logiciels et outils de simulation utilisés pour
                  véhicule électrique et chaine de traction.
                </p>
              </div>
              {/* /.col-lg-4 */}
            </div>
          </div>
          <section className="bg-light page-section" id="team">
            <div className="text-center">
              <h2 className="mt-4 mb-4">Comité de Gestion</h2>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="team-member">
                    <img
                      className="mx-auto rounded-circle respo-pic"
                      src={profileImg}
                      alt
                    />
                    <h4>M. Belkhayat Driss</h4>
                    <p className="text-muted">Responsable de la filière</p>
                    <ul className="list-inline social-buttons">
                      <li className="list-inline-item">
                        <a href="mailto:driss.belkhayat@uca.ma">
                          <i className="fa fa-envelope" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://www.facebook.com/dbelkhayat"
                          target="_blank"
                        >
                          <i className="fa fa-facebook-f" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://www.linkedin.com/in/driss-belkhayat-4b511519/"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg">
                  <div className="team-member">
                    <img
                      className="mx-auto rounded-circle"
                      src={profileImg5}
                      alt
                    />
                    <h4>M. Elwarraki Elmostafa</h4>
                    <p className="text-muted"></p>
                  </div>
                </div>
                <div className="col-lg">
                  <div className="team-member">
                    <img
                      className="mx-auto rounded-circle"
                      src={profileImg4}
                      alt
                    />
                    <h4>M. Ayad Hassan</h4>
                    <p className="text-muted"></p>
                  </div>
                </div>
                <div className="col-lg">
                  <div className="team-member">
                    <img
                      className="mx-auto rounded-circle"
                      src={profileImg3}
                      alt
                    />
                    <h4>M. Chennani Mohamed</h4>
                    <p className="text-muted"></p>
                  </div>
                </div>
                <div className="col-lg">
                  <div className="team-member">
                    <img
                      className="mx-auto rounded-circle"
                      src={profileImg2}
                      alt
                    />
                    <h4>M. Elkari Abdeljalil </h4>
                    <p className="text-muted"></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Card>
      </React.Fragment>
    );
  }
}

export default Home;
