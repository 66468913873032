import React, { Component } from "react";

import "./style.css";

class PostCard extends Component {
  render() {
    const { blog } = this.props;
    return (
      <div className="col-12 col-sm-8 col-md-6 col-lg-4 mt-3 mb-3">
        <div className="card">
          <img className="card-img" src={blog.attributes.Image} alt="img" />

          <div className="card-body">
            <div className="badge badge-success badge-pill">
              {blog.attributes.Categorie}
            </div>
            <h4 className="card-title">{blog.attributes.Titre}</h4>

            <p numberOfLines={1} className="card-text">
              {blog.attributes.Text.substr(0, 100) + "..."}
            </p>
            <a href={"/post/" + blog.id} className="btn btn-info">
              Lire la suite
            </a>
          </div>
          <div className="card-footer text-muted d-flex justify-content-between bg-transparent border-top-0">
            <div className="views">{blog.attributes.Date}</div>
            <div className="stats">
              <i className="fa fa-eye" /> 100
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PostCard;
