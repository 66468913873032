import React, { Component } from "react";
import "./style.css";
import SideBar from "../SideBar";

class Layout extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="container">
          {this.props.children}
          <SideBar />
        </div>
      </React.Fragment>
    );
  }
}

export default Layout;
