import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import "./style.css";

class Programme extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.getUrl = this.getUrl.bind(this);
    this.state = { show: false, pdfUrl: "" };
  }

  handleClose(event) {
    this.setState({ show: false });
  }
  handleShow(event) {
    const loadedPdfUrl = event.target.attributes.url.value;
    const fixedUrlParte =
      "https://drive.google.com/viewerng/viewer?embedded=true&url=";
    const joined = loadedPdfUrl + fixedUrlParte;
    this.setState({ show: true, pdfUrl: joined });
  }

  getUrl(url) {
    return "https://drive.google.com/viewerng/viewer?embedded=true&url=" + url;
  }
  render() {
    return (
      <React.Fragment>
        <h3 style={{ color: "#ffffff" }} className="text-center mb-3 mt-3">
          Programme de la filière
        </h3>

        <div className="row ">
          <div className="col mb-4 ">
            <div className="card h-100">
              <h5
                className="card-header text-center"
                style={{ color: "#eca425" }}
              >
                S1
              </h5>
              <div className="card-body">
                <ul className="list-group">
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M11.pdf?alt=media&token=e3222c3f-09b3-43a9-8d76-a62db072350e
                    "
                  >
                    M11- Langues et Communication 1
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M12.pdf?alt=media&token=4af0eb1e-6cb4-452d-8289-034160380158

                    "
                  >
                    M12- Entreprenariat et Economie d’entreprise
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M13.pdf?alt=media&token=672b533a-0bdd-4942-b946-2408ea147563
                    "
                  >
                    M13- Analyse numérique <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M14.pdf?alt=media&token=c0e6be78-451f-476f-b7fe-8ae3d4d6d391


                    "
                  >
                    M14- Semi-conducteurs pour l'énergétique
                    <br />{" "}
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M15.pdf?alt=media&token=c23fea2f-b103-4f1b-95fd-2d4db696acef


                    "
                  >
                    M15- Electronique analogique
                    <br />{" "}
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M16.pdf?alt=media&token=6c4965a7-e065-4981-9a4d-b625516fbbac


                    "
                  >
                    M16- Traitement de signal <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M17.pdf?alt=media&token=8ea3af08-ef80-42d8-b61b-b0d2811d3d74


                    "
                  >
                    M17- Electronique numérique 1
                    <br />{" "}
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M18.pdf?alt=media&token=d7bbd435-4504-4372-ad4d-4834627ffba2
                    "
                  >
                    M18- Thermodynamique appliquée
                    <br />
                  </a>
                </ul>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <h5
                className="card-header text-center"
                style={{
                  color: "#eca425",
                }}
              >
                S2
              </h5>
              <div className="card-body">
                <ul className="list-group">
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M21.pdf?alt=media&token=b68579b0-e9ea-40a8-a592-4a44c6bae9f1



                    "
                  >
                    M21- Langues et Communication 2
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M22.pdf?alt=media&token=e1515539-0494-4b71-8e6c-0418bd3a6176



                    "
                  >
                    M22- Technique de gestion de l’entreprise
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M23.pdf?alt=media&token=45185bfa-b0c8-44b7-8abd-540cc4cdb7bb



                    "
                  >
                    M23- Probabilités et statistiques & RO
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M24.pdf?alt=media&token=6d946bfa-0538-465d-b213-f09de491b001



                    "
                  >
                    M24- Programmation orientée Objet /Java
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M25.pdf?alt=media&token=8c51878c-74b9-4889-a4ea-e882ed86eb2c



                    "
                  >
                    M25- Bases des télécommunications et CEM
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M26.pdf?alt=media&token=fe6d0e4c-7fdc-4f10-a558-1d945c9df770



                    "
                  >
                    M26- DSP et Microcontrôleurs
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M27.pdf?alt=media&token=6e7ec31f-e19f-4cb4-86af-7c7888f4a176



                    "
                  >
                    M27- Electrotechnique 1
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M28.pdf?alt=media&token=85318d70-86b3-4dfd-a74d-1926004dbfe1



                    "
                  >
                    M28- Automatique 1 : systèmes asservis
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col mb-4">
            <div className="card h-100">
              <h5
                className="card-header text-center "
                style={{ color: "#3a3e95" }}
              >
                S3
              </h5>
              <div className="card-body">
                <ul className="list-group">
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M31.pdf?alt=media&token=946350d5-c7b6-42d3-97e3-ba0b8b8f8088

                    "
                  >
                    M31- Langues et Communication 3
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M32.pdf?alt=media&token=bf65eaac-690a-4673-b092-d206f4314b35


                    "
                  >
                    M32- Management de l’environnement de l’entreprise
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M33.pdf?alt=media&token=10c9eeef-41a1-46c3-b56e-099e517da29f


                    "
                  >
                    M33- Dessin Industriel & Techniques de réalisation
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M34.pdf?alt=media&token=c50e340c-4fc6-4a35-a0e2-328da7058a0c


                    "
                  >
                    M34- Réseaux de communication et Protocoles
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M35.pdf?alt=media&token=1389b9fe-3db4-4b6b-a941-0510be25db45


                    "
                  >
                    M35- Capteurs, Actionneurs et Automatisme
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M36.pdf?alt=media&token=55b87721-c102-42a6-9836-0ab033884dcf


                    "
                  >
                    M36- Electrotechnique 2
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M37.pdf?alt=media&token=83002387-a44c-417e-94db-de468c13931d


                    "
                  >
                    M37- Système temps réel, Intelligence artificielle
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M38.pdf?alt=media&token=14db0ee3-f2e3-48e7-a8ae-638b839e89cc
                    "
                  >
                    M38- Automatique 2 : Systèmes échantillonnés
                    <br />
                  </a>
                </ul>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card  h-100">
              <h5
                className="card-header text-center"
                style={{ color: "#3a3e95" }}
              >
                S4
              </h5>
              <div className="card-body">
                <ul className="list-group">
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M41.pdf?alt=media&token=de676aae-c992-48fb-b59b-a7eac6a62c63

                    "
                  >
                    M41- Langues et Communication 4
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M42.pdf?alt=media&token=ca0e940e-4fe0-4513-8933-431c0f5dd470

                    "
                  >
                    M42- Management de projet et gestion des services publics
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M43.pdf?alt=media&token=2603b2be-1406-4770-ac81-abe7681ce097

                    "
                  >
                    M43- Electronique de puissance
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M44.pdf?alt=media&token=5664e148-d18a-4a67-9609-a3754ab8735f

                    "
                  >
                    M44- Systèmes logiques embarqués
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M45.pdf?alt=media&token=a89e7119-b45f-4476-a402-aea547178c1f

                    "
                  >
                    M45- Régulation industrielle
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M46.pdf?alt=media&token=5484ba17-68dc-410b-ab89-ce60adb3ebeb

                    "
                  >
                    M46- Réseaux locaux industriels & Supervision
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M47.pdf?alt=media&token=d1c9927d-500b-40d8-a26c-6c5d77e8d7d3
                    "
                  >
                    M47- Machines électriques
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M48.pdf?alt=media&token=031ca7cf-e6e4-4b77-b494-80c62235d032
                    "
                  >
                    M48- Projets & réalisations
                    <br />
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col mb-4">
            <div className="card h-100">
              <h5
                className="card-header text-center"
                style={{ color: "#4da85d" }}
              >
                S5 Option: ÉNERGIES RENOUVELABLES
              </h5>
              <div className="card-body">
                <ul className="list-group">
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M151.pdf?alt=media&token=f800b93a-994b-4dfa-85fc-d2d285e9c9ee


                    "
                  >
                    M151- Matériaux et technologies photovoltaïques
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M152.pdf?alt=media&token=6b6413e7-3f90-43f6-8809-ee4b5e6b42c8


                    "
                  >
                    M152- Energie solaire photovoltaïque & thermique
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M153.pdf?alt=media&token=fc7807e9-dbdf-45ea-ba00-1232f92df541


                    "
                  >
                    M153- Energie Eolienne : Gisement, production et
                    dimensionnement
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M154.pdf?alt=media&token=6648ded0-1da5-4ef9-85ad-18f7fae38f36


                    "
                  >
                    M154- Commande des systèmes photovoltaïques et Eoliennes
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M155.pdf?alt=media&token=eddc5f93-14e0-484a-88be-ecee9d0bb947


                    "
                  >
                    M155- Audit et efficacité énergétique & Transition
                    énergétique / Normes
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M156.pdf?alt=media&token=6902d8f8-c8a4-4b5d-a9bf-7b4e0231b106


                    "
                  >
                    M156- Softwares pour les énergies renouvelables et mobilité
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M157.pdf?alt=media&token=7b14c351-ebbc-402e-b76a-f7b3c584ae3a


                    "
                  >
                    M157- Intégration des énergies renouvelables sur le réseau
                    électrique
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M158.pdf?alt=media&token=deec4f7b-b564-457e-a6ef-920c5a85ac5f


                    "
                  >
                    M158- Stockage et Cogénération
                    <br />
                  </a>
                </ul>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card  h-100">
              <h5
                className="card-header text-center"
                style={{ color: "#4da85d" }}
              >
                S5 Option: MOBILITE ELECTRIQUE
              </h5>
              <div className="card-body">
                <ul className="list-group">
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M251.pdf?alt=media&token=383362f7-9242-4318-b0bb-87c4e26f2faa



                    "
                  >
                    M251- Commande avancée des machines
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M252.pdf?alt=media&token=71a59275-8337-4329-a66c-9033f165fa92
                    "
                  >
                    M252- Réseaux Electriques, Gestion et Distribution
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M253.pdf?alt=media&token=436888e4-1024-4812-aea2-8be542e09e8d
                    "
                  >
                    M253- Mobilité électriques & Transports Nouveaux
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M254.pdf?alt=media&token=d4313beb-3260-4073-9b8c-e1e7dbd784a6



                    "
                  >
                    M254- Commande des systèmes de mobilité électrique – chaine
                    de traction
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M255.pdf?alt=media&token=28929ef5-1b4c-495e-9f67-f2def7b06189
                    "
                  >
                    M255- Audit et efficacité énergétique & Transition
                    énergétique / Normes
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M256.pdf?alt=media&token=3a20a452-abc3-4734-a523-c5bc8c9e397a
                    "
                  >
                    M256- Softwares pour les énergies renouvelables et mobilité
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M257.pdf?alt=media&token=721c7883-e5ed-46c5-8d41-57424588ad4b
                    "
                  >
                    M257- Couplage des Centrales Solaires aux réseaux
                    industriels
                    <br />
                  </a>
                  <a
                    onClick={this.handleShow}
                    url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M258.pdf?alt=media&token=27d210f5-b1dd-4216-aacd-3d691e4c106a
                    "
                  >
                    M258- Installations Electriques intelligentes
                    <br />
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card text-center">
          <h5 className="card-header text-center" style={{ color: "#4da85d" }}>
            S6
          </h5>
          <div className="card-body">
            <a
              onClick={this.handleShow}
              url="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FFiches_De_Modules%2FFICHE_DE_MODULE_M600.pdf?alt=media&token=7b30222f-e06b-42c6-a5bf-9aa3b932086d
                    "
            >
              Projet fin d'etude
            </a>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleClose}
          animation={true}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <iframe
              src={this.state.pdfUrl}
              frameBorder="0"
              scrolling="auto"
              height="700"
              width="100%"
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Programme;
