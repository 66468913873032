import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import './style.css'
class FooterBar extends Component {
  state = {};
  render() {
    return (
      <footer className="py-5 footer mt-4">
        <container>
          <p className="m-0 text-center text-black">
            Copyright © ERME FSTG 2020
          </p>
        </container>
      </footer>
    );
  }
}

export default FooterBar;
