/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import Image from "../../assets/img/ep.jpg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class ERME_NEWS extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.state = { show: false, videoUrl: "" };
  }

  handleClose(event) {
    this.setState({ show: false });
  }
  handleShow(event) {
    console.log();
    const loadedVideoUrl = event.target.attributes.url.value;
    this.setState({ show: true, videoUrl: loadedVideoUrl });
  }
  render() {
    return (
      <React.Fragment>
        <h3 style={{ color: "#ffffff" }} className="text-center mb-3 mt-3">
          ERME_NEWS
        </h3>
        <div className="row row-cols-1 row-cols-md-3 mt-3">
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/16Uwb1AL29s"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Episode 1</h5>
              <div className="card-body text-center">
                Vers les énergies vertes et l'utilisation de la mobilités
                électriques
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/_TGCVIWuRz8"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Episode 2</h5>
              <div className="card-body text-center">
                L'évolution des énergies renouvelables & la mobilités électrique
                au royaume
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/YUgfVpDV8Ks"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Episode 3</h5>
              <div className="card-body text-center">
                Capteur sphérique (Rawlemon) & Samsung SDI batterie
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/gQOGi4HLZQA"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Episode 4</h5>
              <div className="card-body text-center">
                L'électricité sans fil & Centrale solaire Hybride NOOR MIDELT
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/OF03xF_X61E"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Episode 5</h5>
              <div className="card-body text-center">
                Voiture électrique, hybride, et à hydrogène
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/prLA2lmqraE"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Episode 6</h5>
              <div className="card-body text-center">
                Pavegen & SolarStratos
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/J6ge-5pXnF4"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Episode 7</h5>
              <div className="card-body text-center">
                Spring Electrical & La Borne de Recharge Intelligent iSmart
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/GUXkzm3tnoc"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Episode 8</h5>
              <div className="card-body text-center">
                Installation des panneaux solaires & Construction d'un parc
                eolien
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleClose}
          animation={true}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>ERME NEWS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="500"
              src={this.state.videoUrl}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ERME_NEWS;
