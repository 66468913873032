import React, { Component } from "react";
import "./style.css";
import Card from "../UI/Card";
import blogPost from "../../data/blog.json";
import { NavLink } from "react-router-dom";

class SideBar extends Component {
  constructor(props) {
    super();
    this.state = {
      posts: [],
    };
  }
  componentDidMount() {
    const posts = blogPost.data;
    this.setState({ posts: posts });
  }
  render() {
    const { posts } = this.state;
    return (
      <div
        className="sidebarContainer"
        style={{
          width: this.props.width,
        }}
      >
        <Card
          style={{
            marginBottom: "20px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div className="cardHeader">
            <span>About Us</span>
          </div>
          <div className="profileImageContainer">
            <img src={"/LOGO_ERME.PNG"} alt="LOGO ERME" />
          </div>
          <div className="cardBody">
            <p className="personalBio">
              ÉNERGIES RENOUVELABLES & MOBILITÉ ÉLECTRIQUE
            </p>
          </div>
        </Card>

        <Card
          style={{
            marginBottom: "20px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div className="cardHeader">
            <span>Social Network</span>
          </div>
        </Card>

        <Card
          style={{
            marginBottom: "20px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div className="cardHeader">
            <span>Recent Posts</span>
          </div>

          <div className="recentPosts">
            {posts.map((post) => {
              return (
                <a key={post.id} href={`/post/${post.id}`}>
                  <div className="recentPost">
                    <h3>{post.blogTitle}</h3>
                    <span>{post.postedOn}</span>
                  </div>
                </a>
              );
            })}
          </div>
        </Card>
      </div>
    );
  }
}

export default SideBar;
