import React from "react";

import "./App.css";
import Home from "./containers/Home";
import Header from "./components/Header";
import Hero from "./components/Hero";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import ContactUs from "./containers/ContactUs";
import Post from "./containers/Post";
import FooterBar from "./components/Footer";
import Programme from "./components/Programme";
import ERME_NEWS from "./components/ERME_NEWS";
import Actualite from "./containers/Actualite";
import Promotions from "./components/Promotions";
//import Soon from "./components/Soon";
import Equipe from "./components/Equipe";
import Emploi from "./components/Emploi";
import Partenariat from "./components/Partenariat";
import Club from "./components/Club";
import Stage from "./components/Stage";
import Debouches from "./components/Debouches";
import Validation from "./components/Validation";
import Admission from "./components/Admission";
import Cv from "./components/Cv";
import BreakingNews from "./components/BreakingNews";
import Project from "./components/Project";
import Inscription from "./components/Inscription";

function App() {
  return (
    <Router>
      <Switch>
      <Route exact path='/trombinoscope-promo-5' component={() => { 
     window.location.href = 'https://forms.gle/TGnh9mqpKgW7igKi7'; 
     return null;
        }}/>
        <div className="App">
          <BreakingNews />
          <Header />
          <Hero />
          <Route exact path="/" component={Home} />
          <Route path="/contact-us" component={ContactUs} />
          <Route exact path="/post" component={Actualite} />
          <Route path="/post/:slug" component={Post} />
          <Route path="/programme" component={Programme} />
          <Route path="/debouches" component={Debouches} />
          <Route path="/validation" component={Validation} />
          <Route path="/admission" component={Admission} />
          <Route path="/equipe" component={Equipe} />
          <Route path="/partenariat" component={Partenariat} />
          <Route path="/emplois" component={Emploi} />
          <Route path="/stage" component={Stage} />
          <Route path="/promotions" component={Promotions} />
          <Route path="/club-erme" component={Club} />
          <Route path="/erme-news" component={ERME_NEWS} />
          <Route path="/projets" component={Project} />
          <Route path="/cv" component={Cv} />
          <Route path="/inscription" component={Inscription} />
          <FooterBar />
        </div>
      </Switch>
    </Router>
  );
}

export default App;
