import React, { Component } from "react";
import Card from "../UI/Card";
//import "./style.css";

class Stage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Card>
          <div className="row">
            <div className="col-8">
              <div className="mt-3 ml-4">
                <p>
                  A la fin de la première et de la deuxième année du cycle
                  ingénieur, chaque étudiant doit passer un stage d'été de
                  découverte et d’immersion dans le milieu professionnel. Ce
                  stage permet aux étudiants d’apprendre la culture de
                  l’entreprise, de mettre en pratique les connaissances et
                  compétences acquises durant l’année universitaire et de
                  développer d’autres compétences aussi bien techniques que
                  transversales à travers l’affrontement quotidien avec des
                  problématiques diverses.
                  <br />
                  Ces stages doivent aboutir à la conception et la réalisation
                  d’une application.
                  <br />
                  Les deux stages se déroulent dans une entreprise ou dans une
                  administration publique d’une durée d’un mois (4 semaines).
                  <br />
                  Le stage est validé si :
                  <div className="ml-5">
                    1- La durée est respectée.
                    <br />
                    2- La note de stage doit être supérieure ou égale à 12.
                    <br />
                    3- La note de chaque stage est comptabilisée de 5% du module
                    PFE.
                    <br />
                  </div>
                </p>
                <h3>Stage 1 :</h3>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Intitulé du Stage</th>
                        <th scope="col">Durée</th>
                        <th scope="col">Période</th>
                        <th scope="col">Objectifs</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Stage d'été d'initiation</td>
                        <td>4 Semaines</td>
                        <td>Juillet/août</td>
                        <td>
                          - Découvrir l’entreprise et observer ses modes de
                          fonctionnement.
                          <br /> - Acquérir des comportements professionnels.{" "}
                          <br />- Mettre en pratique les connaissances et
                          compétences acquises durant l’année universitaire.
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3>Stage 2 :</h3>
                <table className="table table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Intitulé du Stage</th>
                      <th scope="col">Durée</th>
                      <th scope="col">Période</th>
                      <th scope="col">Objectifs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Stage Ingénieur 2ème année</td>
                      <td>8 Semaines</td>
                      <td>Juillet/août</td>
                      <td>
                        - Comprendre les installations techniques et les
                        systèmes de contrôle mis en place au sein des
                        entreprises.
                        <br /> - Mettre en pratique les connaissances et
                        compétences acquises durant l’année universitaire.
                        <br /> - Acquérir une autonomie comportementale.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h3>Stage : PFE</h3>
                <p>
                  {}
                  Le Projet de fin d’études constitue le passage de la formation
                  au sein de l’université à la vie professionnelle et le métier
                  d’ingénieur. Le PFE doit être réalisé de préférence en
                  entreprise au Maroc ou à l’étranger, il doit durer entre
                  quatre et six mois durant le sixième semestre de la formation.
                  Le PFE vise à placer l’élève ingénieur dans une situation se
                  rapprochant de ce qu’il sera amené à découvrir dans la vie
                  active.
                  <br />
                  <br />
                  {"    "}Durant toute la durée du PFE l’étudiant est encadré
                  par un ingénieur de la société où est réalisé le projet et par
                  un cadre scientifique de l’université appartenant de
                  préférence à l’équipe pédagogique de la formation.
                  <br />
                  <br />
                  {"    "}Sur le plan technique, le projet vise à résoudre dans
                  un délai limité un problème industriel en utilisant les
                  connaissances acquises lors de la formation et en tenant
                  compte de tous les facteurs techniques économiques et de
                  production.
                  <br />
                  <br />
                  {"    "}Le PFE est couronné par la rédaction d’un mémoire
                  constituant la synthèse des travaux réalisés et il est
                  sanctionné par une soutenance publique.
                </p>
              </div>
            </div>
            <div className="col-lg">
              <div className="mt-3 ml-4 mr-4 justify-content-center">
                <h3 className="text-center">Convention de Stage</h3>
                <a href="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2Fconvention-stage.doc?alt=media&token=ef7d8600-8559-411c-986d-1cac5d755782">
                  <button
                    type="button"
                    className="btn btn-lg btn-warning btn-block"
                  >
                    Télécharger le modèle
                  </button>
                </a>
              </div>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default Stage;
