import React, { Component } from "react";
import Card from "../UI/Card";
import "./style.css";
import imgAMEE from "../../assets/img/partenaires/AMEE.png";
import imgHEEC from "../../assets/img/partenaires/HEEC.png";
import imgNAREVA from "../../assets/img/partenaires/NAREVA.jpg";
import imgNORSYS from "../../assets/img/partenaires/norsys.png";
import imgIRESEN from "../../assets/img/partenaires/IRESEN.jpeg";

class Partenariat extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Card>
          <div>
            <h1 className="text-center mt-3">Engagement et Soutiens :</h1>
          </div>
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-lg">
                <div className="partenaire text-center">
                  <img className="img-thumbnail" src={imgNAREVA} />
                  <p className="text-muted">NAREVA</p>
                </div>
              </div>
              <div className="col-lg">
                <div className="partenaire text-center">
                  <img className="img-thumbnail" src={imgIRESEN} />
                  <p className="text-muted">IRESEN</p>
                </div>
              </div>
              <div className="col-lg">
                <div className="partenaire text-center">
                  <img className="img-thumbnail" src={imgAMEE} />
                  <p className="text-muted ">AMEE</p>
                </div>
              </div>
              <div className="col-lg">
                <div className="partenaire text-center">
                  <img className="img-thumbnail" src={imgHEEC} />
                  <p className="text-muted">HEEC</p>
                </div>
              </div>

              <div className="col-lg">
                <div className="partenaire text-center">
                  <img className="img-thumbnail" src={imgNORSYS} />
                  <p className="text-muted">Norsys Afrique</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default Partenariat;
