import React, { Component } from "react";
import Card from "../../components/UI/Card";
import "./style.css";
import BlogPost from "../../components/BlogPost";
import SideBar from "../../components/SideBar";
import Layout from "../../components/Layout";
class Post extends Component {
  state = {};
  render() {
    return (
      <Layout>
        <BlogPost {...this.props} />
      </Layout>
    );
  }
}

export default Post;
