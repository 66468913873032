import React, { Component } from "react";
import Card from "../UI/Card";

class Validation extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Card>
          <h3 className="text-center mb-3 mt-3">MODALITES DE VALIDATION</h3>
          <div className="d-flex justify-content-center">
            <div className="container-fluid">
              <h5>Validation de l’année</h5>
              <p>
                Pour chaque semestre, le jury est composé du coordonnateur
                pédagogique de la filière, président, des coordonnateurs des
                modules dispensés au cours du semestre et des enseignants qui
                assurent l’encadrement de ces modules. Une année d’une filière
                du cycle ingénieur est validée et donne droit à l’inscription à
                l’année suivante si les trois conditions suivantes sont
                satisfaites :
                <br />
                <div className="ml-5">
                  − La moyenne générale de l'année est supérieure ou égale à 12
                  sur 20.
                  <br />
                  − Le nombre de modules non validés de l'année est inférieur à
                  6.
                  <br />− Aucune note de module n'est inférieure à 8 sur 20.
                  <br />
                </div>
                <i>
                  Le jury d’année est composé du Chef d’établissement ou son
                  représentant, Président, du coordonnateur pédagogique de la
                  filière et des coordonnateurs des modules dispensés au cours
                  des deux semestres de l’année considérée
                </i>
              </p>
              <h5>Validation du 5ème semestre</h5>
              <p>
                La moyenne générale du cinquième semestre est égale à la moyenne
                des notes des différents modules suivis durant ce semestre. Le
                cinquième semestre d’une filière du cycle Ingénieur est validé
                si les trois conditions suivantes sont satisfaites :
                <br />
                <div className="ml-5">
                  − La moyenne générale du cinquième semestre est supérieure ou
                  égale à 12 sur 20.
                  <br />− Le nombre de modules non validés du semestre est
                  inférieur ou égal à 3.
                  <br />− Aucune note de module n'est inférieure à 8 sur 20.
                  <br />
                </div>
                <i>
                  Le jury de semestre est composé du Chef d’établissement ou son
                  représentant, Président, du coordonnateur pédagogique de la
                  filière, des coordonnateurs des modules dispensés au cours du
                  semestre et d’enseignants qui assurent l’encadrement de ces
                  modules
                </i>
              </p>
              <h5>JURY DE LA FILIERE</h5>
              <p>
                Le jury pour l’attribution du diplôme est composé du Chef
                d’établissement ou de son représentant, Président, du
                coordonnateur pédagogique de la filière et des coordonnateurs
                des modules de la filière.
              </p>
              <h5>Obtention du diplôme</h5>
              <p>
                L’étudiant obtient le diplôme s’il valide, les deux premières
                années, le cinquième semestre et le PFE. La moyenne globale,
                servant pour l’obtention du diplôme et la détermination des
                mentions, est une moyenne pondérée. Les pondérations utilisées
                pour le calcul de cette moyenne sont :
                <br />
                <div className="ml-5">
                  − Moyenne générale de 1ère année x 30%
                  <br />− Moyenne générale de 2ème année x 30%
                  <br />− Moyenne générale du cinquième semestre x 20%
                  <br />− Note du PFE x 20%
                </div>
              </p>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default Validation;
