import React, { Component } from "react";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.state = {
      name: "",
      email: "",
      message: "",
    };
  }
  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  handleSubmitTemp(event){
    event.preventDefault();
    alert("Message failed to send. Try later");
    this.resetForm();
  }
  handleSubmit(event) {
    event.preventDefault();

    console.log(this.state);
    fetch("http://localhost:3002/send", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          alert("Message Sent.");
          this.resetForm();
        } else if (response.status === "fail") {
          alert("Message failed to send.");
        }
      });
  }

  resetForm() {
    this.setState({ name: "", email: "", message: "" });
  }
  render() {
    return (
      <div className="mt-2">
        <div className="row">
          <div className="col">
        <form
          id="contact-form"
          onSubmit={this.handleSubmitTemp.bind(this)}
          method="POST"
        >
          <div className="form-group">
            <label className="text-light">Nom</label>
            <input value={this.state.name} onChange={this.onNameChange} type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label className="text-light">Email</label>
            <input
            value={this.state.email}
            onChange={this.onEmailChange}
              type="email"
              className="form-control"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="form-group">
            <label className="text-light">Votre Message</label>
            <textarea value={this.state.message}
            onChange={this.onMessageChange} className="form-control" rows="5"></textarea>
          </div>
          <button type="submit" className="btn btn btn-warning">
            Envoyer
          </button>
        </form>
        </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
