/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import Card from "../UI/Card";

class Promotions extends Component {
  constructor(){
    super();
    this.state = {
      trombinoscope:[],
    };
  }

  async componentDidMount() {
    
    // Simple GET request using fetch
    const response = await fetch('https://cpanel.fi-erme.com/api/trombinoscopes?populate=image_trombinoscope');
    const json = await response.json();
    this.setState({ trombinoscope: json.data.reverse() });
}
  
  render() {
    const list = [
      'https://res.cloudinary.com/dvexh7r3m/raw/upload/v1673783701/Listes_d_Etudiants_ERME_1_1_2022_2023_fc93b6c517.xlsx?updated_at=2023-01-15T11:55:01.254Z',
      'https://res.cloudinary.com/dvexh7r3m/raw/upload/v1673783701/Listes_d_Etudiants_ERME_1_2_2022_2023_7c25e7280e.xlsx?updated_at=2023-01-15T11:55:01.280Z',
      'https://res.cloudinary.com/dvexh7r3m/raw/upload/v1673783701/Listes_d_Etudiants_ERME_1_3_2022_2023_ad723a5420.xlsx?updated_at=2023-01-15T11:55:01.497Z'
    ]
    return (
      <Card>
        {this.state.trombinoscope && this.state.trombinoscope.map((trom, index)=>(
          <div className="mt-4">
          <div className="mt-4 d-flex justify-content-center">
            <img src={trom.attributes.image_trombinoscope.data.attributes.url} className="img-fluid" alt="Responsive image" />
          </div>
          { index <= 2 ?
            <a href={list[index]}>
            <button type="button" className="btn btn-lg btn-warning btn-block">
              {index===0 ? 'Télécharger la liste des étudiants de 1ère année ERME' : null}
              {index===1 ? 'Télécharger la liste des étudiants de 2ème année ERME' : null}
              {index===2 ? 'Télécharger la liste des étudiants de 3ème année ERME' : null}
            </button>
          </a> : null}
        </div>
        ))}
      </Card>
    );
  }
}

export default Promotions;
