import React, { Component } from "react";
import Card from "../UI/Card";
import "./style.css";
class Inscription extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="">
          <Card>
            <div className="mt-4">
              <h1 className="text-center">
                La Liste principale et d'attente des candidats retenus
              </h1>
            </div>
            <div className="row">
              <div className="col">
                <div className="mt-5 mb-5">
                  <div className="mt-1 ml-4">
                    <b>
                      Les inscriptions auront lieu :<br></br>
                      <i class="fa fa-hand-o-right mr-1"></i>
                      Les 16, 17, 20 et 21 Septembre 2021 pour les admis de la
                      liste principale.<br></br>
                      <i class="fa fa-hand-o-right mr-1"></i>
                      Du 23 au 24 septembre 2021 pour la liste d'attente en cas
                      de places vacantes.
                    </b>
                  </div>
                  <div className="mt-1 ml-4">
                    <i class="fa fa-hand-o-right"></i>
                    <a
                      href="http://www.fstg-marrakech.ac.ma/ER-ME_2021.pdf"
                      className="ml-1"
                    >
                      Télécharger La Liste
                    </a>
                  </div>
                  <div className="mt-1 ml-4">
                    <i class="fa fa-hand-o-right"></i>
                    <a
                      href="http://intranet.fstg-marrakech.ac.ma/apix/"
                      target="_blank"
                      className="ml-1"
                    >
                      Préinscription en ligne obligatoire.
                    </a>
                  </div>

                  <div className="mt-1 ml-4">
                    <i class="fa fa-hand-o-right"></i>
                    <a
                      href="http://www.fstg-marrakech.ac.ma/PIECES_FOURNIR-CI.pdf"
                      className="ml-1"
                    >
                      Pièces à fournir
                    </a>
                  </div>

                  <div className="mt-1 ml-4">
                    <i class="fa fa-hand-o-right"></i>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/doc%2FBrochure_ERME_compressed.pdf?alt=media"
                      className="ml-1"
                      target="_blank"
                    >
                      La brochure de la formation Filière d’ingénieur Energies
                      Renouvelables & Mobilité Electrique (ER&ME)
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Inscription;
