import React, { Component } from "react";
import Card from "../UI/Card";
import Gallery from "react-grid-gallery";

const IMAGES = [
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG-20191219-WA0005.jpg?alt=media&token=b0b28482-9c75-4c5c-bcd4-3c790d62911b",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG-20191219-WA0005.jpg?alt=media&token=b0b28482-9c75-4c5c-bcd4-3c790d62911b",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG_20191128_151903_1.jpg?alt=media&token=a066c006-5c6e-4884-85e2-b3d0ff617fe2",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG_20191128_151903_1.jpg?alt=media&token=a066c006-5c6e-4884-85e2-b3d0ff617fe2",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG-20200201-WA0019.jpg?alt=media&token=2e7dfce6-d39d-4ee3-bf6a-519c3a4021fe",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG-20200201-WA0019.jpg?alt=media&token=2e7dfce6-d39d-4ee3-bf6a-519c3a4021fe",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG-20200201-WA0017.jpg?alt=media&token=708970b0-64e2-485d-8a17-7d9e94429d9a",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG-20200201-WA0017.jpg?alt=media&token=708970b0-64e2-485d-8a17-7d9e94429d9a",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2F20200209_130008.jpg?alt=media&token=73ba9531-8166-44cb-abd7-ea043f3930f4",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2F20200209_130008.jpg?alt=media&token=73ba9531-8166-44cb-abd7-ea043f3930f4",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2F20200209_111732.jpg?alt=media&token=2c9dd172-3914-4349-8027-637f1e7c152d",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2F20200209_111732.jpg?alt=media&token=2c9dd172-3914-4349-8027-637f1e7c152d",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG-20200226-WA0022.jpg?alt=media&token=2eadb054-0182-4982-8f20-869a32534f98",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG-20200226-WA0022.jpg?alt=media&token=2eadb054-0182-4982-8f20-869a32534f98",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG_20200302_193059.jpg?alt=media&token=2649658c-7899-4749-87fb-db32c8b14226",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/erme20192020.appspot.com/o/images%2Fclub%2FIMG_20200302_193059.jpg?alt=media&token=2649658c-7899-4749-87fb-db32c8b14226",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(11).jpeg?alt=media&token=1ca495ee-ce8a-42ad-a83e-b08d1b149cc0",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(11).jpeg?alt=media&token=1ca495ee-ce8a-42ad-a83e-b08d1b149cc0",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(12).jpeg?alt=media&token=e5530242-743c-487a-9c55-a2ba4183845d",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(12).jpeg?alt=media&token=e5530242-743c-487a-9c55-a2ba4183845d",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(13).jpeg?alt=media&token=2b050a2f-e30b-4422-bff3-76b137f70deb",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(13).jpeg?alt=media&token=2b050a2f-e30b-4422-bff3-76b137f70deb",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(14).jpeg?alt=media&token=8fdbca4f-1b74-4d2d-8a76-829a7f265caf",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(14).jpeg?alt=media&token=8fdbca4f-1b74-4d2d-8a76-829a7f265caf",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(1).jpeg?alt=media&token=328df1be-2d1c-4def-bcbd-66e5b31d167a",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(1).jpeg?alt=media&token=328df1be-2d1c-4def-bcbd-66e5b31d167a",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(2).jpeg?alt=media&token=743429aa-d366-43ab-bb8a-ee065914d3aa",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(2).jpeg?alt=media&token=743429aa-d366-43ab-bb8a-ee065914d3aa",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(3).jpeg?alt=media&token=65407249-0b44-4fa9-9b31-aab7e60107f7",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(3).jpeg?alt=media&token=65407249-0b44-4fa9-9b31-aab7e60107f7",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(4).jpeg?alt=media&token=c303e690-f421-4d4d-980b-cdeccf8ec21c",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(4).jpeg?alt=media&token=c303e690-f421-4d4d-980b-cdeccf8ec21c",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(5).jpeg?alt=media&token=47ede3b7-cc08-4f3b-af29-c91c45245bb2",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(5).jpeg?alt=media&token=47ede3b7-cc08-4f3b-af29-c91c45245bb2",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(6).jpeg?alt=media&token=3f0044c9-1266-4b98-a4b3-99929097d753",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(6).jpeg?alt=media&token=3f0044c9-1266-4b98-a4b3-99929097d753",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(7).jpeg?alt=media&token=70159603-ab34-4504-ba44-4ad68ff68852",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(7).jpeg?alt=media&token=70159603-ab34-4504-ba44-4ad68ff68852",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(8).jpeg?alt=media&token=669c874e-beea-4c80-bdaf-8991b1340c8a",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(8).jpeg?alt=media&token=669c874e-beea-4c80-bdaf-8991b1340c8a",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(9).jpeg?alt=media&token=cc3df9c3-9dea-44e8-919e-7bcc6a77af66",
    thumbnail:
      "",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(10).jpeg?alt=media&token=077adf0d-a092-4306-a723-efe2417f2396",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/fi-erme.appspot.com/o/images%2Fclub%2Fphoto%20(10).jpeg?alt=media&token=077adf0d-a092-4306-a723-efe2417f2396",
  },
  

];

class Club extends Component {
  constructor(){
    super();
    this.state = {
      images:[],
    };
  }

  async componentDidMount() {
    
    // Simple GET request using fetch
    const response = await fetch('https://cpanel.fi-erme.com/api/club-galleries?populate=images');
    const json = await response.json();
    console.log(json.data);
    json.data.reverse().forEach(element => {
      element.attributes.images.data.reverse().forEach(image => {
          this.setState({images: [...this.state.images, {src:image.attributes.url, thumbnail: image.attributes.url}]});  
      });
    });
    // this.setState({ images: json.data.reverse() });
    
}

  render() {
    return (
      <React.Fragment>
        <div className="">
          <Card>
            <div className="mt-4">
              <h1 className="text-center">Club ERME</h1>
            </div>
            <div className="container-fluid">
              <p style={{ fontSize: "24px" }}>
                Un club de la FSTG MARRAKECH qui est initié par les étudiants de
                la filière d’ingénieur : énergie renouvelable et mobilté
                électrique, consiste à développer l’esprit para-universitaire et
                encourager la créativité et l’innovation.
              </p>
            </div>
            <div className="mt-4">
              <h1 className="text-center">Gallery</h1>
            </div>
            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
              }}
            >
              <Gallery images={this.state.images} />
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Club;
