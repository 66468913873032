import React, { Component } from "react";
import "./style.css";
import Card from "../UI/Card";
// import blogPost from "../../data/blog.json";
import { marked } from "marked";

class BlogPost extends Component {
  constructor(props) {
    super();
    this.state = {
      post: null,
      slug: "",
    };
  }

  async componentDidMount(event) {
    const slug = this.props.match.params.slug;
    // const post = blogPost.data.find((post) => post.slug == slug);
    // this.setState({
    //   post: post,
    //   slug: slug,
    // });

     // Simple GET request using fetch
     const response = await fetch(
      "https://fi-erme-cpanel.herokuapp.com/api/actualites/"+slug
    );
    const json = await response.json();
    this.setState({ post: json.data });
  }



  render() {
    const { post } = this.state;
    return (
      <div className="blogPostContainer">
        {post && 
        <Card>
        <div className="blogHeader">
          <span className="blogCategory">{post.attributes.Categorie}</span>
          <h1 className="postTitle">{post.attributes.Titre}</h1>
          <span className="postedBy">
            posted on {post.attributes.Date} by 
            {/* {post.attributes.author} */}
            Admin
          </span>
        </div>

        <div className="postImageContainer">
          <img src={post.attributes.Image} alt="Post Image" />
        </div>

        <div className="postContent">
          <h3>{post.attributes.Titre}</h3>
          <p>
            <div
                dangerouslySetInnerHTML={{
                  __html: marked(post.attributes.Text),
                }}
              ></div>
            </p>
        </div>
      </Card>
        }
      </div>
    );
  }
}

export default BlogPost;
