import React, { Component } from "react";
import Layout from "../../components/Layout";
import "./style.css";
import PostCard from "../../components/UI/PostCard";
import blogPost from "../../data/blog.json";

class Actualite extends Component {
  constructor() {
    super();
    this.state = {
      actualites: [],
    };
  }

  async componentDidMount() {
    // Simple GET request using fetch
    const response = await fetch(
      "https://fi-erme-cpanel.herokuapp.com/api/actualites"
    );
    const json = await response.json();
    this.setState({ actualites: json.data });
  }

  render() {
    return (
      <div>
        <div className="container">
          
          <div className="row row-cols-1 row-cols-md-3 mt-3">
            {this.state.actualites.map((post) => {
              return <PostCard blog={post} />;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Actualite;
