import React, { Component } from "react";
import "./style.css";

class BreakingNews extends Component {
  state = {};
  render() {
    return (
      <div id="hellobar-bar" className="regular closable">
        {/* <div className="hb-content-wrapper">
          <div className="hb-text-wrapper">
            <div className="hb-headline-text">
              <span className="secondary">
                <b>
                   <h3 className="badge badge-danger blink_me">New </h3> Emplois du temps 2022-2023
                  
                  
                </b>
              </span>
            </div>
          </div>
          <a href="/emplois" className="hb-cta hb-cta-button">
            <div className="hb-text-holder">
              <p>Emplois du temps</p>
            </div>
          </a>
        </div> */}
      </div>
    );
  }
}

export default BreakingNews;
