import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Navbar2 from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
//import "./style.css";
class Navbar extends Component {
  constructor() {
    super();
    this.subbmitSearch = this.subbmitSearch.bind(this);
    this.openSearch = this.openSearch.bind(this);
    this.state = {
      search: false,
    };
  }

  subbmitSearch(event) {
    alert();
    event.preventDefault();
  }

  openSearch(event) {
    this.setState({ search: true });
  }
  render() {
    const searchClass = this.state.search
      ? "searchInput active"
      : "searchInput";
    return (
      <Navbar2 collapseOnSelect expand="lg" bg="light">
        <Navbar2.Brand href="#home"></Navbar2.Brand>
        <img
          src="/LOGO_ERME.png"
          width="9%"
          height="9%"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
        <Navbar2.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar2.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Accueil</Nav.Link>
            <NavDropdown title="Formation">
              <NavDropdown.Item href="/admission">Admission</NavDropdown.Item>
              <NavDropdown.Item href="/programme">Programme</NavDropdown.Item>
              <NavDropdown.Item href="/validation">Validation</NavDropdown.Item>
              <NavDropdown.Item href="/debouches">Débouchés</NavDropdown.Item>
              <NavDropdown.Item href="/equipe">
                Equipe Pédagogique
              </NavDropdown.Item>
              <NavDropdown.Item href="/promotions">Promotions</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Espace Etudiant">
              <NavDropdown.Item href="/emplois">
                Emplois du temps
              </NavDropdown.Item>
              <NavDropdown.Item href="/stage">Info Stage</NavDropdown.Item>
              <NavDropdown.Item href="/cv">Liste des CV</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/post">Actualites</Nav.Link>
            <Nav.Link href="/erme-news">EMRE NEWS</Nav.Link>
            <Nav.Link href="/projets">Projets</Nav.Link>
            <Nav.Link href="/club-erme">Club EMRE </Nav.Link>
            <Nav.Link href="/partenariat">Partenariat </Nav.Link>
            <Nav.Link target="_blank" href="https://jobs.fi-erme.com">Jobs</Nav.Link>
            <Nav.Link href="/contact-us">Contact</Nav.Link>
          </Nav>
        </Navbar2.Collapse>
      </Navbar2>
    );
  }
}

export default Navbar;
