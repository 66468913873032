import React, { Component } from "react";
import Card from "../UI/Card";

class Admission extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Card>
          <h3 className="text-center mb-3 mt-3">MODALITES D’ADMISSION</h3>
          <div className="d-flex justify-content-center">
            <div className="container-fluid">
              <h6>CONDITIONS D’ACCES :</h6>
              <p>
                <b>
                  <u>- Accès en première année :</u>
                </b>
                <br />
                <div className="ml-4">
                  + Candidats ayant validé les deux années préparatoires au
                  cycle ingénieur.
                  <br />+ Candidats ayant réussi le concours national commun
                  d’admission dans les établissements de formation d’ingénieurs
                  et établissements assimilés.
                  <br />+ Titulaires des diplômes suivants : <br />
                  <div className="ml-5">
                    DEUG. <br /> DUT. <br /> DEUST. <br /> DEUP.
                    <br />
                    Licence.
                  </div>
                </div>
                <br />
                <b>
                  <u>- Accès en DEUXIÈME année (Passerelles): </u>
                </b>
                <br />
                <div className="ml-4">
                  Peuvent accéder à la deuxième année (3ème semestre) les
                  étudiants titulaires : <br />
                  <div className="ml-5">
                    - D’une Licence et de la première année de Master (M1) dans
                    les spécialités suivantes :
                    <div className="ml-5">
                      + Electronique, Electrotechnique, Automatique et
                      Informatique. <br /> + Génie Electrique. <br /> + Energies
                      Renouvelables.
                      <br />
                    </div>
                    - D’un diplôme reconnu au moins équivalent à une Licence
                    dans le domaine du Génie Electrique.
                  </div>
                </div>
                <br />
                <h6>PROCEDURES DE SELECTION :</h6> - Concours national commun.
                <br />- Concours spécifique à l’établissement d’accueil :
                <div className="ml-5">
                  <b>+ Etude du dossier :</b> La sélection porte essentiellement
                  sur le parcours du candidat (les mentions, nombre d’années,
                  etc.)
                  <br /> <b>+ Examen écrit: </b>épreuve de Mathématiques (30%),
                  Physique (30%), Informatique (20%), Français (20%) <br />
                  <b>+ Entretien : </b> Le candidat doit montrer sa motivation
                  au domaine des énergies et avoir des qualités de
                  communications et une bonne culture générale.
                  <br />
                </div>
                <br />
                <h6>PRE-REQUIS PEDAGOGIQUES POUR L’ACCES A LA FILIERE :</h6>
                <div className="ml-5">
                  + Mathématiques. <br /> + Physique. <br /> + Informatique.{" "}
                  <br /> + Langues & Communication.
                  <br />
                </div>
              </p>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default Admission;
